.bb8-basic-flex, .bb8-nav-bar, .bb8-nav-bar .bb8-title-wapper {
  display: flex;
  flex-direction: row;
  align-items: center; }

/*------------------------------------*\
    Brand styles
\*------------------------------------*/
/*
 * All branded styling for Air Miles
 *
 * Includes:
 * Brand Color Manifest
 * Color Map Function
 * Background color convenience classes
 * Text color convenience classes
 */
.am-background--white {
  background-color: #fff; }

.am-background--black {
  background-color: #231f20; }

.am-background--onyx {
  background-color: #000; }

.am-background--grey-dark {
  background-color: #5e5e5e; }

.am-background--grey {
  background-color: #7c7e80; }

.am-background--grey-light-1 {
  background-color: #969899; }

.am-background--grey-light-2 {
  background-color: #b0b2b3; }

.am-background--grey-light-3 {
  background-color: #cbcbcc; }

.am-background--grey-light-4 {
  background-color: #e5e5e6; }

.am-background--grey-accent {
  background-color: #bbbdbf; }

.am-background--grey-accent-light-1 {
  background-color: #c9cacc; }

.am-background--grey-accent-light-2 {
  background-color: #d6d7d9; }

.am-background--grey-accent-light-3 {
  background-color: #e4e5e5; }

.am-background--grey-accent-light-4 {
  background-color: #f1f2f2; }

.am-background--blue-darker {
  background-color: #12619a; }

.am-background--blue {
  background-color: #1790cc; }

.am-background--blue-light-1 {
  background-color: #45a6d6; }

.am-background--blue-light-2 {
  background-color: #74bce0; }

.am-background--blue-light-3 {
  background-color: #a2d3eb; }

.am-background--blue-light-4 {
  background-color: #d1e9f5; }

.am-background--blue-accent {
  background-color: #61c1ee; }

.am-background--blue-accent-light-1 {
  background-color: #81cdf1; }

.am-background--blue-accent-light-2 {
  background-color: #a0daf5; }

.am-background--blue-accent-light-3 {
  background-color: #c0e6f8; }

.am-background--blue-accent-light-4 {
  background-color: #dff3fc; }

.am-background--teal {
  background-color: #15a4aa; }

.am-background--teal-light-1 {
  background-color: #44b6bb; }

.am-background--teal-light-2 {
  background-color: #73c8cc; }

.am-background--teal-light-3 {
  background-color: #a1dbdd; }

.am-background--teal-light-4 {
  background-color: #d0edee; }

.am-background--teal-accent {
  background-color: #69c8cf; }

.am-background--teal-accent-light-1 {
  background-color: #87d3d9; }

.am-background--teal-accent-light-2 {
  background-color: #a5dee2; }

.am-background--teal-accent-light-3 {
  background-color: #c3e9ec; }

.am-background--teal-accent-light-4 {
  background-color: #e1f4f5; }

.am-background--green {
  background-color: #44a648; }

.am-background--green-light-1 {
  background-color: #69b86d; }

.am-background--green-light-2 {
  background-color: #8fca91; }

.am-background--green-light-3 {
  background-color: #b4dbb6; }

.am-background--green-light-4 {
  background-color: #daedda; }

.am-background--green-accent {
  background-color: #bed630; }

.am-background--green-accent-light-1 {
  background-color: #cbde59; }

.am-background--green-accent-light-2 {
  background-color: #d8e683; }

.am-background--green-accent-light-3 {
  background-color: #e5efac; }

.am-background--green-accent-light-4 {
  background-color: #f2f7d6; }

.am-background--orange {
  background-color: #de7426; }

.am-background--orange-light-1 {
  background-color: #e59051; }

.am-background--orange-light-2 {
  background-color: #ebac7d; }

.am-background--orange-light-3 {
  background-color: #f2c7a8; }

.am-background--orange-light-4 {
  background-color: #f8e3d4; }

.am-background--orange-accent {
  background-color: #fcb813; }

.am-background--orange-accent-light-1 {
  background-color: #fdc642; }

.am-background--orange-accent-light-2 {
  background-color: #fdd471; }

.am-background--orange-accent-light-3 {
  background-color: #fee3a1; }

.am-background--orange-accent-light-4 {
  background-color: #fef1d0; }

.am-background--purple {
  background-color: #8e499b; }

.am-background--purple-light-1 {
  background-color: #a56daf; }

.am-background--purple-light-2 {
  background-color: #bb92c3; }

.am-background--purple-light-3 {
  background-color: #d2b6d7; }

.am-background--purple-light-4 {
  background-color: #e8dbeb; }

.am-background--purple-accent {
  background-color: #b271ae; }

.am-background--purple-accent-light-1 {
  background-color: #c18dbe; }

.am-background--purple-accent-light-2 {
  background-color: #d1aace; }

.am-background--purple-accent-light-3 {
  background-color: #e0c6df; }

.am-background--purple-accent-light-4 {
  background-color: #f0e3ef; }

.am-background--pink {
  background-color: #d30e8b; }

.am-background--pink-light-1 {
  background-color: #dc3ea2; }

.am-background--pink-light-2 {
  background-color: #e56eb9; }

.am-background--pink-light-3 {
  background-color: #ed9fd1; }

.am-background--pink-light-4 {
  background-color: #f6cfe8; }

.am-background--pink-accent {
  background-color: #d963a4; }

.am-background--pink-accent-light-1 {
  background-color: #e182b6; }

.am-background--pink-accent-light-2 {
  background-color: #e8a1c8; }

.am-background--pink-accent-light-3 {
  background-color: #f0c1db; }

.am-background--pink-accent-light-4 {
  background-color: #f7e0ed; }

.am-background--gold {
  background-color: #a28836; }

.am-background--gold-light-1 {
  background-color: #b5a05e; }

.am-background--gold-light-2 {
  background-color: #c7b886; }

.am-background--gold-light-3 {
  background-color: #dacfaf; }

.am-background--gold-light-4 {
  background-color: #ece7d7; }

.am-background--link-blue {
  background-color: #0a6fb3; }

.am-background--copy {
  background-color: #5e5e5e; }

.am-background--copy-dark {
  background-color: #231f20; }

.am-background--error-light {
  background-color: #f9e8f1; }

.am-background--alert-success {
  background-color: #f2f7d6; }

.am-background--alert-warning {
  background-color: #fff1d9; }

.am-background--alert-fail {
  background-color: #f9e7f1; }

.am-background--alert-info {
  background-color: #daedf7; }

.am-text--white {
  color: #fff; }

.am-text--black {
  color: #231f20; }

.am-text--onyx {
  color: #000; }

.am-text--link-blue {
  color: #0a6fb3; }

.am-text--copy {
  color: #5e5e5e; }

.am-text--copy-dark {
  color: #231f20; }

.am-text--gold {
  color: #a28836; }

.am-text--grey {
  color: #7c7e80; }

.am-text--grey-dark {
  color: #5e5e5e; }

.am-text--blue {
  color: #1790cc; }

.am-text--blue-accent {
  color: #61c1ee; }

.am-text--blue-darker {
  color: #12619a; }

.am-text--teal {
  color: #15a4aa; }

.am-text--teal-accent {
  color: #69c8cf; }

.am-text--green {
  color: #44a648; }

.am-text--green-accent {
  color: #bed630; }

.am-text--orange {
  color: #de7426; }

.am-text--orange-accent {
  color: #fcb813; }

.am-text--purple {
  color: #8e499b; }

.am-text--purple-accent {
  color: #b271ae; }

.am-text--pink {
  color: #d30e8b; }

.am-text--pink-accent {
  color: #d963a4; }

.bb8-basic-flex, .bb8-nav-bar, .bb8-nav-bar .bb8-title-wapper {
  display: flex;
  flex-direction: row;
  align-items: center; }

.bb8-nav-bar-wrapper {
  margin: 0 auto;
  background-size: contain;
  height: 96px;
  box-shadow: none;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden; }
  .bb8-nav-bar-wrapper h1 {
    color: #fff;
    font-size: 40px; }

.bb8-nav-bar-wrapper-prod {
  background-image: none;
  background-color: #51253d; }

.bb8-nav-bar {
  font-family: "Omnes", Calibri, "Trebuchet MS";
  width: 1366px; }
  @media screen and (max-width: 1365px) {
    .bb8-nav-bar {
      width: 100%; } }
  .bb8-nav-bar * {
    flex-grow: 1; }
  .bb8-nav-bar .bb8-title-wapper * {
    margin-right: 1em; }
  .bb8-nav-bar .bb8-title-wapper h1 {
    font-weight: lighter; }
